import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, sumBy } from 'lodash';
import { useSelector } from 'react-redux';
import { markenStandortSelector, matomoTagManagerContainerSelector } from '../../../../modules/selectors/standort/markenStandort';
import { canTrackSelector } from '../../../../modules/selectors/oneTrust';
import { isClient } from '../../../../helpers/SSRHelper';
import { isSearchResultsSelector, searchStatsSelector } from '../../../../modules/selectors/search';
import { pageSelector } from '../../../../modules/selectors/url';
import { currentHostMatomoSiteIdSelector } from '../../../../modules/selectors/hosts';
import { Helmet } from 'react-helmet';

function pushInstruction(name, standort = null, ...args) {
    if (isClient) {
        if(standort && standort.matomoTagManagerContainer){
            if (!window._mtm) {
                window._mtm = [];
            }
            window._mtm.push([name, ...args]);
        }
        else{
            if (!window._paq) {
                window._paq = [];
            }
            window._paq.push([name, ...args]);
        }
    } else {
        console.warn("Can't track server-side:", name, ...args);
    }
}

const matomoDimensionIDs = { standortUrl: 2, gesellschafterNr: 4, hauptFabrikat: 6, page: 8 };

//This is how we record user interactions that don't trigger a change in URL.
export const trackEvent = ({ category, action, label: name, value, standort }) => {
    if (value) value = Math.trunc(value);

    if (action) {
        console.debug('trackEvent', standort, { category: category || 'Unkategorisiert', action, name, value });

        pushInstruction('trackEvent', standort, category || 'Unkategorisiert', action, name, value);
    } else {
        console.warn('event action name is required');
    }
};

const trackEcommerceOrder = ({ orderId, grandTotal, subTotal, tax, shipping, standort }) => {
    pushInstruction('trackEcommerceOrder', standort, orderId, grandTotal, subTotal, tax, shipping);
};

const addEcommerceItem = ({ productSKU, productName, productCategory, price, quantity, standort }) => {
    pushInstruction('addEcommerceItem', standort, productSKU, productName, productCategory, price, quantity);
};

const legalTypeToEventLabel = legalType => {
    switch (legalType) {
        case 'online-kauf':
        case 'Einkauf':
            return 'Einkauf';
        case 'reservierung':
        case 'Reservierung':
            return 'Reservierung';
        default:
            throw new Error(`Unexpected legalType: ${legalType}`);
    }
};
export const trackCheckoutStart = ({ legalType, standort }) => {
    trackEvent({
        category: 'E-Commerce',
        action: 'Checkout-Start',
        label: legalTypeToEventLabel(legalType),
        standort: standort,
    });
};
export const trackCheckoutEnd = ({ legalType, standort }) => {
    trackEvent({
        category: 'E-Commerce',
        action: 'Checkout-Ende',
        label: legalTypeToEventLabel(legalType),
        standort: standort,
    });
};
export const trackEcommerceTransaction = ({ tracking: { products, id, revenue, shipping, tax }, legalType, standort }) => {
    products.forEach(({ name, sku, category, price, quantity }) => {
        addEcommerceItem({ productSKU: sku, productName: name, productCategory: category, price, quantity, standort });
    });

    trackEcommerceOrder({
        orderId: id,
        grandTotal: revenue,
        tax: tax,
        shipping: shipping,
        subTotal: sumBy(products, product => product?.price ?? 0),
        standort: standort,
    });

    trackCheckoutEnd({
        legalType,
        standort: standort,
    });
};

const trackSiteSearch = (standort, { term, count }) => {
    // Reference: https://developer.matomo.org/guides/tracking-javascript-guide#internal-search-tracking
    pushInstruction('trackSiteSearch', standort, term, false, count);
};

export const Matomo = () => {
    const currentHostMatomoSiteId = useSelector(currentHostMatomoSiteIdSelector);
    const markenStandort = useSelector(markenStandortSelector);
    const matomoTagManagerContainer = useSelector(matomoTagManagerContainerSelector);
    const page = useSelector(pageSelector);
    const canTrack = useSelector(canTrackSelector);
    const isSearchResults = useSelector(isSearchResultsSelector);
    const searchStats = useSelector(searchStatsSelector);

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (currentHostMatomoSiteId || matomoTagManagerContainer) {
            if (markenStandort !== undefined) {
                if (markenStandort.title === 'Ernst Dello | Area Hamburg') {
                    pushInstruction('setTrackerUrl', markenStandort, 'https://dello-gruppe.de/matomo/matomo.php');
                    pushInstruction('setSiteId', markenStandort, currentHostMatomoSiteId);
                    setIsInitialized(true);
                } else {
                    pushInstruction('setTrackerUrl', markenStandort, 'https://autoteileplusservice.matomo.cloud/matomo.php');
                    pushInstruction('setSiteId', markenStandort, currentHostMatomoSiteId);
                    setIsInitialized(true);
                }
            }
        } else {
            console.error('Matomo tracking disabled, missing host.matomoSiteId');
        }
    }, [currentHostMatomoSiteId, markenStandort, matomoTagManagerContainer]);

    useEffect(() => {
        // Reference: https://developer.matomo.org/guides/tracking-consent
        if (canTrack) {
            pushInstruction('setConsentGiven', markenStandort);
            pushInstruction('HeatmapSessionRecording::enable', markenStandort);
        } else {
            pushInstruction('requireConsent', markenStandort);
            pushInstruction('HeatmapSessionRecording::disable', markenStandort);
        }
    }, [canTrack, markenStandort]);

    useEffect(() => {
        // Reference: https://matomo.org/faq/how-to/faq_33087/
        pushInstruction('alwaysUseSendBeacon', markenStandort);
    }, [markenStandort]);

    useEffect(() => {
        if (!isEmpty(markenStandort)) {
            const { url, gesellschafterNr, hauptFabrikat } = markenStandort;

            pushInstruction('setCustomDimension', markenStandort, matomoDimensionIDs.standortUrl, url);
            pushInstruction('setCustomDimension', markenStandort, matomoDimensionIDs.gesellschafterNr, gesellschafterNr);
            pushInstruction('setCustomDimension', markenStandort, matomoDimensionIDs.hauptFabrikat, hauptFabrikat);
        }
    }, [markenStandort]);

    useEffect(() => {
        if (!isEmpty(markenStandort)) {
            pushInstruction('setCustomDimension', markenStandort, matomoDimensionIDs.page, page);
            pushInstruction('setCustomUrl', markenStandort, window.location.href);
            // TODO: fix stale title
            pushInstruction('setDocumentTitle', markenStandort, window.document.title);

            if (isSearchResults) {
                // Don't track pageviews on search result pages.
                // Reference: https://developer.matomo.org/guides/tracking-javascript-guide#internal-search-tracking
            } else {
                pushInstruction('trackPageView', markenStandort);
            }
        }
    }, [markenStandort, page, isSearchResults]);

    useEffect(() => {
        if (searchStats !== null) {
            trackSiteSearch(markenStandort, searchStats);
        }
    }, [searchStats, markenStandort]);

    return (
        <Helmet>
            {isInitialized && canTrack && (
                <script type="text/javascript" async src="//cdn.matomo.cloud/autoteileplusservice.matomo.cloud/matomo.js" />
            )}
        </Helmet>
    );
};

const EXTERNAL_REL = 'noopener noreferrer';

/**
 * Render an anchor element with Matomo link tracking.
 *
 * Matomo recommends a complete rescan of the DOM after *every* DOM update. [Docs](https://developer.matomo.org/guides/spa-tracking#link-tracking)
 * This seems wasteful, so instead we register each external link manually using "addListener". [Docs](https://developer.matomo.org/api-reference/tracking-javascript#advanced-uses)
 */
export const ListenerLink = ({ children, to, ...props }) => {
    const linkRef = useRef(null);
    const markenStandort = useSelector(markenStandortSelector)

    useEffect(() => {
        if (linkRef.current) {
            pushInstruction('addListener', markenStandort, linkRef.current);
        }
    }, [markenStandort]);

    return (
        // The only <a href="external link" /> in this project.
        // eslint-disable-next-line react/forbid-elements
        <a {...props} rel={EXTERNAL_REL} ref={linkRef} href={to}>
            {children}
        </a>
    );
};

ListenerLink.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
};

import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { isString } from 'lodash';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { pathnameSelector } from '../../../modules/selectors/url';
import { DEPLOYMENT_ENV } from '../../../config';
import { originSelector } from '../../../modules/selectors/vHostRequestLocation';
import { additionalCrumbsSelector } from '../../../modules/selectors/additionalCrumbs';
import { categoryUrlSelector, standortUrlSelector } from '../../../modules/selectors/url';
import { categoriesSelector } from '../../../modules/selectors/standort/categories';
import { getBaseCrumbs } from '../BreadCrumb/breadCrumbHelper';

function HeadMeta({ seoMetaDataResponse, markenStandortResponse }) {
    const pathname = useSelector(pathnameSelector);
    const origin = useSelector(originSelector);
    const standortUrl = useSelector(standortUrlSelector);
    const categories = useSelector(categoriesSelector);
    const categoryUrl = useSelector(categoryUrlSelector);
    const location = useLocation();
    let additionalCrumbs = useSelector(additionalCrumbsSelector);
    if (!additionalCrumbs) {
        additionalCrumbs = [];
    }
    const crumbs = useMemo(() => {
        const crumbs = getBaseCrumbs({ standortUrl, categories, categoryUrl });
        return [...crumbs, ...additionalCrumbs];
    }, [standortUrl, categories, categoryUrl, additionalCrumbs]);

    const seoMetaData = seoMetaDataResponse.data ?? {};

    const titlePrefix = isString(seoMetaData.title) ? `${seoMetaData.title}` : '';
    let titlePostfix = markenStandortResponse.data?.seoMetaDataTitlePostfix ? ` | ${markenStandortResponse.data?.seoMetaDataTitlePostfix}` : '| Autoteile plus Service';
    if (titlePostfix === " |  " || titlePrefix.includes(" | ")) {
        titlePostfix = "";
    }
    const standort = markenStandortResponse.data ?? {};

    let robotsContent;
    if (DEPLOYMENT_ENV === 'test' || DEPLOYMENT_ENV === 'abnahme') {
        // Hide public test environments from bots.
        robotsContent = 'noindex, nofollow';
    } else {
        robotsContent = seoMetaData.robots || 'noindex, follow';
    }

    let canonicalHref;
    if (seoMetaData.canonical) {
        canonicalHref = seoMetaData.canonical;
    } else {
        canonicalHref = `${origin}${pathname}`;
    }
    const Baseorigin = origin.split('//');
    return (
        <Helmet>
            <title>
                {titlePrefix}
                {titlePostfix}
            </title>
            {/* clarity tag for Gramling */}
            {standort.url !== "autohaus-gramling-nordbaden" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                (function(c,l,a,r,i,t,y){
                    c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "dnzg8wrbd1");
                `}
                </script>}
            {/* Hotjar tag for Gramling */}
            {standort.url !== "autohaus-gramling-nordbaden" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                (function(h,o,t,j,a,r){
                    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings={hjid:3158767,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}
                </script>}

            {/* clarity tag for Techno APS */}
            {standort.url !== "de" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                        (function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; 
                        t=l.createElement(r);
                        t.async=1;
                        t.src="https://www.clarity.ms/tag/"+i; 
                        y=l.getElementsByTagName(r)[0];
                        y.parentNode.insertBefore(t,y); 
                        })(window, document, "clarity", "script", "dg653i9loa"); 
                    `}
                </script>}
            {/* clarity tag for NOA */}
            {standort.title !== "Nord Ostsee | Area Hamburg" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                    (function(c,l,a,r,i,t,y){
                        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "dkgl9gao8k");
                `}
                </script>}
            {/* Hotjar tag for NOA */}
            {standort.title !== "Nord Ostsee | Area Hamburg" ? '' :
                // Hotjar Tracking Code for https://shop.nord-ostsee-automobile.de/ 
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                    (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:3149293,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}
                </script>}
            {/* clarity tag for Dello*/}
            {standort.url !== "ernst-dello-grossraum-hamburg" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                    (function(c,l,a,r,i,t,y){
                        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "dhans0or6y");
                `}
                </script>}
            {/* Hotjar tag for Dello*/}
            {standort.url !== "ernst-dello-grossraum-hamburg" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                (function(h,o,t,j,a,r){            
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};            
                    h._hjSettings={hjid:3138184,hjsv:6};            
                    a=o.getElementsByTagName('head')[0];            
                    r=o.createElement('script');r.async=1;            
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;            
                    a.appendChild(r);            
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                `}
                </script>}


            {/* clarity and hotjar script for wittler */}
            {standort.url !== "witteler-mercedes-smart" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                        (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "dhacf0qzrv");
                    `}
                </script>}
            {standort.url !== "witteler-mercedes-smart" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                         (function(h,o,t,j,a,r){

                            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    
                            h._hjSettings={hjid:3149267,hjsv:6};
                    
                            a=o.getElementsByTagName('head')[0];
                    
                            r=o.createElement('script');r.async=1;
                    
                            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    
                            a.appendChild(r);
                    
                        })(window,document,' https://static.hotjar.com/c/hotjar-','.js?sv= ');
                    `}
                </script>}

            {/* google tag for NOA */}
            {/* {standort.title !== "Nord Ostsee | Area Hamburg" ? '' : <script async src="https://www.googletagmanager.com/gtag/js?id=AW-969384639" type="text/plain" class="optanon-category-C0002"></script>}
            {standort.title !== "Nord Ostsee | Area Hamburg" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date()); 
                    gtag('config','AW-969384639' );
                    `}
                </script>
            }
            {standort.title !== "Nord Ostsee | Area Hamburg" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                    function gtag_report_conversion(url) {
                        var callback = function () {
                        if (typeof(url) != 'undefined') {
                        window.location = url;
                        }
                        };
                        gtag('event', 'conversion', {
                        'send_to': 'AW-969384639/TtGECIar2osDEL_Fns4D',
                        'value': 0.01,
                        'currency': 'EUR',
                        'transaction_id': '',
                        'event_callback': callback
                        });
                        return false;
                        }
                    `}
                </script>
            } */}
            {/* dello facebook tracking */}
            {standort.url !== "ernst-dello-grossraum-hamburg" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '1156565265134470');
                    fbq('track', 'PageView');
                    `}
                </script>
            }
            {standort.url !== "ernst-dello-grossraum-hamburg" ? '' : <noscript type="text/plain" class="optanon-category-C0002">{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1156565265134470&ev=PageView&noscript=1"/>`}</noscript>}

            {/* dello google tag */}
            {standort.url !== "ernst-dello-grossraum-hamburg" ? '' :
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-763926529" type="text/plain" class="optanon-category-C0002"></script>
            }
            {standort.url !== "ernst-dello-grossraum-hamburg" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
               window.dataLayer = window.dataLayer || [];
               function gtag(){dataLayer.push(arguments);}
               gtag('js', new Date());
               gtag('config', 'AW-763926529');
               `}
                </script>
            }
            {standort.url !== "ernst-dello-grossraum-hamburg" ? '' :
                <meta name="google-site-verification" content="D9KBCx3CR9KPmDtLucupl0PBKFMygxBIKGlpyzSkutM" />
            }
            <meta name="robots" content={robotsContent} />
            <meta name="google" content="nositelinkssearchbox" />
            {seoMetaData.description && <meta name="description" content={seoMetaData.description} />}
            {standort.title !== "Witteler Mercedes/smart" ? '' : <link rel="icon" type="image/png" href="/favicon-witteler.png" />}
            {standort.url !== "ernst-dello-grossraum-hamburg" ? '' : <link rel="icon" type="image/png" href="/Favicon-DELLO-GRUPPE-Shop.png" />}
            {standort.url !== "auto-scholz-region-bamberg" ? '' : <link rel="icon" type="image/png" href="/MB-star_S_p_4C.jpg" />}
            {standort.url !== "autonova-oberfranken" ? '' : <link rel="icon" type="image/png" href="/Mossbauer-Favicon.png" />}
            {standort.url !== "autoschmitt-frankfurt-idstein" ? '' : <link rel="icon" type="image/png" href="/Favicon AS_sw.png" />}
            {standort.url !== "autoschmitt-frankfurt-idstein" ? '' : <meta name="google-site-verification" content="vO6HLazEQu9jys9wRUoAU6xtCSBKG69kuxcGF1knmaw" />}
            <link rel="canonical" href={canonicalHref} />
            {standort.title !== "Nord Ostsee | Area Hamburg" ? '' : <link rel="icon" type="image/png" href="/NOA-favicon-16x16.png" />}
            {standort.url !== "autohaus-gramling-nordbaden" ? '' : <link rel="icon" type="image/png" href="/gramling-favicon.png" />}
            {standort.url !== "auto-anders-region-bremen" ? '' : <link rel="icon" type="image/png" href="/anders-favicon.png" />}
            {standort.url !== "haeusler-automobile-grossraum-muenchen" ? '' : <link rel="icon" type="image/png" href="/haeusler-favicon.png" />}
            {standort.url !== "hoppmann-region-siegen" ? '' : <link rel="icon" type="image/png" href="/hoppmann-favicon.png" />}
            {standort.url !== "loehrgruppe-region-koblenz" ? '' : <link rel="icon" type="image/png" href="/lohr-favicon.ico" />}
            {standort.url !== "autohaus-tobaben-region-hamburg" ? '' : <link rel="icon" type="image/png" href="/tobaben-favicon.ico" />}
            {standort.url !== "auto-glinicke-region-kassel" ? '' : <link rel="icon" type="image/png" href="https://www.glinicke.de/favicon.ico" />}
            {standort.url !== "gottfried-schultz" ? '' : <link rel="icon" type="image/png" href="/gfs-favicon.png" />}
            {standort.url !== "autohaus-boettche" ? '' : <link rel="icon" type="image/png" href="/autohaus-boettche-favicon.png" />}
            {standort.url !== "auto-fahr-region-fulda" ? '' : <link rel="icon" type="image/png" href="/fahr-jakob-favicon.png" />}
            {standort.url !== "auto-glinicke-region-kassel" ? '' : <script async src="https://www.googletagmanager.com/gtag/js?id=GTM-N3JLP8J" type="text/plain" class="optanon-category-C0002"></script>}
            {standort.url !== "auto-glinicke-region-kassel" ? '' : <script type="text/plain" class="optanon-category-C0002">
                {`
                                                                                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                                                                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                                                                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                                                                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                                                                    })(window,document,'script','dataLayer','GTM-N3JLP8J');
                                                                                `}
            </script>
            }
            {location.pathname !== "/" ? '' :
                <script type="application/ld+json">
                    {`{
                  "@context": "https://schema.org",
                  "@type": "WebSite",
                  "url": "${origin}",
                  "potentialAction": {
                    "@type": "SearchAction",
                    "target": {
                      "@type": "EntryPoint",
                      "urlTemplate": "https://query.${Baseorigin[1]}/search?q={search_term_string}"
                    },
                    "query-input": "required name=search_term_string"
                  }
                }`}
                </script>
            }
            <meta name="google-site-verification" content="mnT5UzSNIFT4w9uQpGdEFf42cmryd_wKb4Ztl4qr6-8" />
            {standort.url !== "gottfried-schultz" ? '' : <script type="text/plain" class="optanon-category-C0002">
                {`!function(f,b,e,v,n,t,s){
                    if(f.fbq)return;n=f.fbq=function()
                    {n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}
                    (window,document,'script','https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '546108804037493'); 
                    fbq('track', 'PageView');                                                              
                    `}
            </script>
            }
            {standort.url !== "gottfried-schultz" ? '' : <script async src="https://connect.facebook.net/en_US/fbevents.js"></script>
            }
            {standort.url !== "gottfried-schultz" ? '' : <noscript>{`<img height="1" width="1" src="https://www.facebook.com/tr?id=546108804037493&ev=PageView&noscript=1"/>`}</noscript>}
            {standort.url !== "gottfried-schultz" ? '' : <meta name="facebook-domain-verification" content="qkl5p2g0n2rdsh2rn1f22k2ykhkkdt" />}
            {/* {standort.url !== "gottfried-schultz" ? '' : <noscript type="text/plain" class="optanon-category-C0002">{`<img height="1" width="1"src="https://www.facebook.com/tr?id=546108804037493&ev=PageView&noscript=1"/>`}</noscript>} */}

            {

                <script type="application/ld+json">
                    {`{
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement":[
                        ${crumbs.map((crumb, index) => {
                        let url = crumb.url;
                        let label = crumb.label;
                        let name = crumb.name;
                        if (url === undefined) {
                            url = `/${label}`;
                        }
                        return (
                            `{
                                    "@type": "ListItem",
                                    "position": ${index},
                                    "name": "${name ?? label}",
                                    "item": "${origin}${url}"
                                }`
                        )
                    })
                        }
                    ]
                }`}
                </script>
            }

            {standort.title !== "TECHNO NATIONAL" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                        (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:3062125,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                    `}
                </script>}
            {/*autohaus-marzahn-grossraum-berlin*/}
            {standort.url !== "autohaus-marzahn-grossraum-berlin" ? '' : <link rel="icon" type="image/png" href="/Marzahn-favicon-16x16.ico" />}
            {/* Audi Zentrum Kassel GmbH & Co. KG */}


            {/* Matomo Tag Manager */}
            {standort.matomoTagManagerContainer == null ? '' :
                <script>
                    {`
                var _mtm = window._mtm = window._mtm || [];
                _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
                (function() {
                    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                    g.async=true; g.src='https://cdn.matomo.cloud/autoteileplusservice.matomo.cloud/container_${standort.matomoTagManagerContainer}.js'; s.parentNode.insertBefore(g,s);
                })();
                `}
                </script>
            }


            {standort.url !== "autohaus-boettche" ? '' :
                <script type="text/plain" class="optanon-category-C0002">
                    {`
                        (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "mnrai2xdu7");
                `}
                </script>
            }

        </Helmet>
    );
}

export default HeadMeta;
